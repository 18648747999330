import React from 'react'
import flatMap from '@/utils/flatMap'
import * as UI from '@/components/UI'
import { Section, TitleBar, Title, Grid, Cell } from './Related.css'

const Related = ({ content, parentSlug }) => {
  const related =
    flatMap(
      content?.related,
      ({ article }) => article?.document,
    ).map(({ data, slug }) => ({ slug, ...data })) || []

  if (!related?.length) return null

  return (
    <Section>
      <UI.Container>
        <TitleBar>
          <Title>{content.related_title}</Title>
          <UI.ArrowLink color='aqua' to={`/${parentSlug}`}>
            {content.related_see_all}
          </UI.ArrowLink>
        </TitleBar>
        <Grid>
          {related.map(article => (
            <Cell key={article.slug}>
              <UI.Card
                to={`/${parentSlug}/${article.slug}`}
                color='aqua'
                title={article.meta_title}
                date={article.date}
                description={article.meta_description}
                image={article?.meta_image}
                tags={article.tags}
                type='article'
              />
            </Cell>
          ))}
        </Grid>
      </UI.Container>
    </Section>
  )
}

export default Related
