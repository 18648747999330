import React from 'react'
import * as UI from '@/components/UI'
import { Section, Cell } from './GallerySlice.css'

const GallerySlice = ({ items }) => {
  if (!items?.length) return null
  return (
    <Section>
      <UI.Container>
        <UI.Grid>
          <Cell>
            <UI.Gallery gallery={items} />
          </Cell>
        </UI.Grid>
      </UI.Container>
    </Section>
  )
}

export default GallerySlice
