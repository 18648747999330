import React from 'react'
import flatMap from '@/utils/flatMap'
import * as UI from '@/components/UI'
import { Section, Main, Cell, ProfileCell } from './Author.css'

const Author = ({ content }) => {
  const authors =
    flatMap(content?.authors, ({ author }) => author?.document).map(
      ({ data }) => data,
    ) || []

  if (!authors?.length) return null

  return (
    <Section>
      <UI.Container>
        <UI.Grid>
          <Cell>
            <Main>
              {authors.map(author => (
                <ProfileCell>
                  <UI.Profile {...author} src={author.image} />
                </ProfileCell>
              ))}
            </Main>
          </Cell>
        </UI.Grid>
      </UI.Container>
    </Section>
  )
}

export default Author
