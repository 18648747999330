import React from 'react'
import * as UI from '@/components/UI'
import { Section, Cell, RichText } from './TextSlice.css'

const TextSlice = ({ primary }) => {
  const html = primary?.text?.html
  if (!html) return null
  return (
    <Section>
      <UI.Container>
        <UI.Grid>
          <Cell>
            <RichText dangerouslySetInnerHTML={{ __html: html }} />
          </Cell>
        </UI.Grid>
      </UI.Container>
    </Section>
  )
}

export default TextSlice
