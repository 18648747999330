import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'

export const Section = styled.section`
  margin: 4rem auto 5rem;
`

export const Main = styled.div`
  border-top: 1px solid ${p => p.theme.colors.gunmetal}80;
`

export const Cell = styled.div`
  margin: auto;
  width: ${getColumnSize(12)};
  ${above('sm')`
    width: ${getColumnSize(10)};
  `}
  ${above('md')`
    width: ${getColumnSize(8)};
  `}
  ${above('xg')`
    width: ${getColumnSize(6)};
  `}
`

export const ProfileCell = styled.div`
  width: 100%;
  margin: 1.5rem 0;
`
