import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'

export const Section = styled.section`
  margin: 4rem auto;
`

export const Cell = styled.div`
  margin: 0 -1rem;
  width: calc(100% + 2rem);
  ${above('md')`
    margin: auto;
    width: ${getColumnSize(10)};
  `}
  ${above('xg')`
    width: ${getColumnSize(8)};
  `}
`
