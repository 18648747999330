import React from 'react'
import flatMap from '@/utils/flatMap'
import * as UI from '@/components/UI'
import {
  Section,
  ImageWrapper,
  Cell,
  Main,
  Tags,
  Tag,
  Title,
  Description,
  Bottom,
  PostDate,
} from './Hero.css'

const Hero = ({ content }) => {
  const authors =
    flatMap(content?.authors, ({ author }) => author?.document).map(
      ({ data }) => data,
    ) || []

  return (
    <Section>
      <ImageWrapper>
        {content.main_image && <UI.Image src={content.main_image} />}
      </ImageWrapper>
      <UI.Container>
        <UI.Grid>
          <Cell>
            <Main>
              {!!content?.tags?.length && (
                <Tags>
                  {content.tags.map(({ tag }) => (
                    <Tag key={tag}>{tag}</Tag>
                  ))}
                </Tags>
              )}
              {content.main_title && <Title>{content.main_title}</Title>}
              {content?.main_description?.html && (
                <Description
                  dangerouslySetInnerHTML={{
                    __html: content.main_description.html,
                  }}
                />
              )}
              <Bottom>
                {authors.map(author => (
                  <UI.Profile
                    name={author.name}
                    src={author.image}
                    size='small'
                  />
                ))}
                <PostDate>{content.date}</PostDate>
              </Bottom>
            </Main>
          </Cell>
        </UI.Grid>
      </UI.Container>
    </Section>
  )
}

export default Hero
