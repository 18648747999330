import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import * as UI from '@/components/UI'

export const Section = styled.section`
  margin: 5rem 0 4rem;
  ${above('lg')`
    margin: 10rem 0 5rem;
  `}
`

export const TitleBar = styled.div`
  margin-bottom: 1.125rem;
  ${above('md')`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 4rem;
  `}
  a {
    padding: 1.25rem 0;
    ${above('md')`
      padding: inherit;
    `}
  }
`

export const Title = styled(UI.Subtitle)`
  font-family: ${p => p.theme.type.fonts.headline};
`

export const Grid = styled(UI.Grid)`
  align-items: stretch;
  height: 100%;
`

export const Cell = styled.div`
  height: auto;
  width: ${getColumnSize(12)};
  ${above('md')`
    width: ${getColumnSize(6)};
  `}
  ${above('lg')`
    width: ${getColumnSize(4)};
  `}
  ${above('xg')`
    width: ${getColumnSize(3)};
  `}
`
