import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import getTypeStyle from '@/utils/getTypeStyle'
import * as UI from '@/components/UI'

export const Section = styled.section`
  margin-bottom: 4rem;
`

export const ImageWrapper = styled.div`
  max-height: 22rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    width: 100%;
  }
  ${above('md')`
    margin: 0 1rem;
  `}
  ${above('lg')`
    max-height: 30rem;
  `}
`

export const Cell = styled.div`
  margin: auto;
  width: ${getColumnSize(12)};
  ${above('sm')`
    width: ${getColumnSize(10)};
  `}
  ${above('md')`
    width: ${getColumnSize(8)};
  `}
  ${above('xg')`
    width: ${getColumnSize(6)};
  `}
`

export const Main = styled.div`
  margin-top: 4rem;
`

export const Tags = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`

export const Tag = styled.li`
  display: flex;
  color: ${p => p.theme.colors.gray};
  text-transform: uppercase;
  font-weight: 500;
  ${getTypeStyle('label')};
  &:after {
    content: '•';
    margin: 0 0.5rem;
  }
  &:last-child {
    &:after {
      content: none;
    }
  }
`

export const Title = styled(UI.SectionTitle).attrs({ as: 'h1' })`
  margin: 1rem 0 1.5rem;
`

export const Description = styled(UI.Text).attrs({ as: 'div' })`
  margin-top: 1.5rem;
`

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1.5rem;
  & > * {
    &:before {
      color: ${p => p.theme.colors.gray};
      content: '·';
      margin: 0 0.5rem;
    }
    &:first-child {
      margin-left: 0;
      &:before {
        content: none;
      }
    }
  }
`

export const PostDate = styled(UI.SmallText).attrs({ as: 'time' })`
  color: ${p => p.theme.colors.gray};
`
