import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import getTypeStyle from '@/utils/getTypeStyle'

export const Section = styled.section`
  margin: -1.5rem auto;
`

export const Cell = styled.div`
  margin: auto;
  width: ${getColumnSize(12)};
  ${above('sm')`
    width: ${getColumnSize(10)};
  `}
  ${above('md')`
    width: ${getColumnSize(8)};
  `}
  ${above('xg')`
    width: ${getColumnSize(6)};
  `}
`

export const BlockQuote = styled.blockquote`
  ${getTypeStyle('text')};
  color: ${p => p.theme.colors.maastricht}CC;
  font-family: ${p => p.theme.type.fonts.headline};
  padding-left: 2rem;
  max-width: 34rem;
  position: relative;
  a {
    color: ${p => p.theme.colors.blue};
    text-decoration: underline;
  }
  &:before {
    content: '';
    position: absolute;
    width: 1px;
    left: 0;
    top: 0;
    bottom: 0;
    background: ${p => p.theme.colors.aqua};
  }
`
