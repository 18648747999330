import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/layout'
import NavBar from '@/components/Partials/NavBar/NavBar'
import Footer from '@/components/Partials/Footer/Footer'
import Hero from '@/components/contents/ArticleTemplate/Hero'
import TextSlice from '@/components/contents/ArticleTemplate/TextSlice'
import QuoteSlice from '@/components/contents/ArticleTemplate/QuoteSlice'
import GallerySlice from '@/components/contents/ArticleTemplate/GallerySlice'
import Author from '@/components/contents/ArticleTemplate/Author'
import Related from '@/components/contents/ArticleTemplate/Related'
import Prefooter from '@/components/Partials/Prefooter/Prefooter'
import getMetaImage from '@/utils/getMetaImage'

const Slice = ({ type, ...rest }) => {
  if (type === 'text') return <TextSlice {...rest} />
  if (type === 'quote') return <QuoteSlice {...rest} />
  if (type === 'gallery') return <GallerySlice {...rest} />
  return null
}

const ArticleTemplate = ({ data }) => {
  const content = data?.content?.data
  const parentSlug = data?.parent?.slug
  const body = content?.body || []

  const metaImage = getMetaImage(content)

  return (
    <Layout
      title={content.meta_title}
      description={content.meta_description}
      image={metaImage}
    >
      <NavBar />
      <Hero content={content} />
      {body.map(({ id, slice_type, ...rest }) => (
        <Slice key={id} type={slice_type} {...rest} />
      ))}
      <Author content={content} />
      <Related content={content} parentSlug={parentSlug} />
      <Prefooter content={content} />
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ArticleTemplate($url: String!, $locale: String!) {
    parent: prismicArticlesPage(lang: { eq: $locale }) {
      slug: uid
    }
    content: prismicArticle(uid: { eq: $url }, lang: { eq: $locale }) {
      data {
        meta_title
        meta_description
        meta_image {
          alt
          url
          localFile {
            childImageSharp {
              fixed(width: 1200, height: 630) {
                src
              }
              fluid {
                aspectRatio
                src
                sizes
                originalName
                srcSet
              }
            }
          }
        }
        main_title
        main_description {
          html
        }
        main_image {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 88) {
                aspectRatio
                src
                sizes
                originalName
                srcSet
              }
            }
          }
        }
        date(formatString: "DD MMMM YYYY", locale: $locale)
        tags {
          tag
        }
        authors {
          author {
            document {
              ... on PrismicAuthor {
                data {
                  name
                  position
                  image {
                    alt
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 128) {
                          aspectRatio
                          src
                          sizes
                          originalName
                          srcSet
                        }
                      }
                    }
                    url
                  }
                }
              }
            }
          }
        }
        body {
          ... on PrismicArticleBodyGallery {
            id
            slice_type
            items {
              title
              image {
                alt
                url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1600) {
                      aspectRatio
                      src
                      sizes
                      originalName
                      srcSet
                    }
                  }
                }
              }
            }
          }
          ... on PrismicArticleBodyQuote {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicArticleBodyText {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
        }
        related_title
        related_see_all
        related {
          article {
            document {
              ... on PrismicArticle {
                slug: uid
                data {
                  meta_title
                  meta_description
                  meta_image {
                    alt
                    url
                    localFile {
                      childImageSharp {
                        fluid {
                          aspectRatio
                          src
                          sizes
                          originalName
                          srcSet
                        }
                      }
                    }
                  }
                  tags {
                    tag
                  }
                }
              }
            }
          }
        }
        prefooter_cards {
          card {
            document {
              ... on PrismicPrefootercard {
                data {
                  title
                  description
                  type {
                    uid
                    type
                    url
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ArticleTemplate
